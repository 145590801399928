var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-2" },
    [
      _c("employment-toolbar", {
        attrs: {
          color: "accent darken-1",
          "btn-color": "accent darken-2",
          "highlight-color": "alternate2 lighten-2",
          "toolbar-items": "screen.employmentEmployerToolBarItems",
          label: _vm.employerData.employment_type,
          is_verified: _vm.employerData.is_verified
        },
        on: { "toolbar-action": _vm.toolbarAction }
      }),
      _c(
        "v-card-text",
        [
          _c("rental-employment-status-bar", {
            attrs: { color: "accent", "request-count": 2 }
          }),
          _c("rental-employment-edit", {
            attrs: {
              title: _vm.title,
              color: "accent",
              data: _vm.employerData,
              "edit-component": "employment-employer-edit",
              edit: _vm.edit
            },
            on: {
              "update:edit": function($event) {
                _vm.edit = $event
              }
            }
          }),
          _c(
            "v-slide-x-reverse-transition",
            [
              !_vm.edit
                ? _c("employment-employer-summary", {
                    attrs: {
                      items: _vm.summaryItems,
                      "icon-color": "accent",
                      "switch-color": "alternate1 lighten-3"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.edit
            ? _c("rental-employment-widget", {
                attrs: {
                  color: "accent",
                  widgetData: _vm.employerData,
                  "menu-options": "screen.employmentEmployerMenuOptions",
                  type: "employer"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("psi-confirm-dialog", { ref: "confirm" }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "940" },
          model: {
            value: _vm.employerDialog,
            callback: function($$v) {
              _vm.employerDialog = $$v
            },
            expression: "employerDialog"
          }
        },
        [
          _vm.employerDialog
            ? _c("employer-landlord-lookup", {
                attrs: {
                  "contact-data": _vm.employerData,
                  type: "employer",
                  color: "accent",
                  icon: "mdi-briefcase-search",
                  title: "Employer Lookup"
                },
                on: {
                  close: function($event) {
                    _vm.employerDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.noteDialog,
            callback: function($$v) {
              _vm.noteDialog = $$v
            },
            expression: "noteDialog"
          }
        },
        [
          _vm.noteDialog
            ? _c("screening-note", {
                attrs: {
                  order_id: _vm.order_id,
                  types: ["Employer"],
                  employers: [_vm.employer]
                },
                on: {
                  close: function($event) {
                    _vm.noteDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }