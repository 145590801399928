<template>
    <v-card class="mt-2">
        <employment-toolbar
            color="accent darken-1"
            btn-color="accent darken-2"
            highlight-color="alternate2 lighten-2"
            toolbar-items="screen.employmentEmployerToolBarItems"
            :label="employerData.employment_type"
            :is_verified="employerData.is_verified"
            @toolbar-action="toolbarAction"
        ></employment-toolbar>
        <v-card-text>
            <rental-employment-status-bar
                color="accent"
                :request-count="2"
            ></rental-employment-status-bar>
            <rental-employment-edit
                :title="title"
                color="accent"
                :data="employerData"
                edit-component="employment-employer-edit"
                :edit.sync="edit"
            ></rental-employment-edit>
            <v-slide-x-reverse-transition>
                <employment-employer-summary
                    :items="summaryItems"
                    icon-color="accent"
                    switch-color="alternate1 lighten-3"
                    v-if="!edit"
                ></employment-employer-summary>
            </v-slide-x-reverse-transition>
            <rental-employment-widget
                color="accent"
                :widgetData="employerData"
                menu-options="screen.employmentEmployerMenuOptions"
                type="employer"
                v-if="!edit"
            ></rental-employment-widget>
        </v-card-text>
        <psi-confirm-dialog ref="confirm"></psi-confirm-dialog>
        <v-dialog v-model="employerDialog" max-width="940">
            <employer-landlord-lookup
                v-if="employerDialog"
                :contact-data="employerData"
                type="employer"
                color="accent"
                icon="mdi-briefcase-search"
                title="Employer Lookup"
                @close="employerDialog = false"
            ></employer-landlord-lookup>
        </v-dialog>
        <!-- Handle the toolbar action of creating a note -->
        <v-dialog v-model="noteDialog" max-width="800">
            <!-- Adding v-if will ensure the component is mounted each time we open the dialog and won't have to reset text area and validation -->
            <screening-note
                v-if="noteDialog"
                :order_id="order_id"
                :types="['Employer']"
                :employers="[employer]"
                @close="noteDialog = false"
            >
            </screening-note>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: "employment-employer",
    components: {
        EmploymentToolbar: () =>
            import("./Common/RentalEmploymentWidgetToolbar"),
        RentalEmploymentStatusBar: () =>
            import("./Common/RentalEmploymentStatusBar"),
        RentalEmploymentEdit: () => import("./Common/RentalEmploymentEdit"),
        EmployerLandlordLookup: () => import("./Common/EmployerLandlordLookup"),
        EmploymentEmployerSummary: () =>
            import("./Common/RentalEmploymentSummary"),
        RentalEmploymentWidget: () => import("./Common/RentalEmploymentWidget"),
    },
    props: {
        employer: {
            type: Object,
            required: true,
        },
        order_id: {
            type: [String, Number],
            required: true,
        },
        summary: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            verify: false,
            edit: false,
            employerDialog: false,
            noteDialog: false,
            employerData: Object.assign({}, this.employer),
        };
    },
    computed: {
        title() {
            return `${this.employerData.company} | ${
                this.employerData.position
            } | ${this.$dollarFormat(
                this.employerData.monthly_income
            )} / month`;
        },
        summaryItems() {
            return [
                {
                    id: this.$uuid.v4(),
                    icon: "mdi-home-city",
                    title: this.$_.get(this.employerData, "company"),
                    description: this.$_.get(this.employerData, "description"),
                    enableVerify: true,
                    verified: this.$_.get(
                        this.employerData,
                        "employment_employer_verified"
                    ),
                },
                {
                    id: this.$uuid.v4(),
                    icon: "mdi-currency-usd",
                    title: `Monthly Income ${this.$dollarFormat(
                        this.$_.get(this.employerData, "monthly_income")
                    )}`,
                    description: "",
                    enableVerify: true,
                    verified: this.$_.get(
                        this.employerData,
                        "employment_income_verified"
                    ),
                },
                {
                    id: this.$uuid.v4(),
                    icon: "mdi-calendar",
                    title: `Employed from ${this.$_.get(
                        this.employerData,
                        "start_date"
                    )} to ${this.$_.get(this.employerData, "end_date", "--")}`,
                    description: `${this.$_.get(
                        this.employerData,
                        "type"
                    )} , ${this.$_.get(
                        this.employerData,
                        "employment_type"
                    )} for ${this.$_.get(this.employerData, "duration")}`,
                    enableVerify: true,
                    verified: this.$_.get(
                        this.employerData,
                        "employment_dates_verified"
                    ),
                },
            ];
        },
    },
    methods: {
        async toolbarAction(action) {
            switch (action) {
                case "employer_lookup":
                    this.employerDialog = true;
                    break;
                case "add_note":
                    this.noteDialog = true;
                    break;
                case "remove_employer":
                    if (
                        await this.$refs.confirm.open(
                            "Confirm",
                            "Are you sure you want to remove this employer? This action cannot be undone.",
                            {
                                headingColor: "accent darken-1",
                                buttonColor: "accent darken-1",
                                icon: "mdi-briefcase-account",
                            }
                        )
                    ) {
                        console.log("Removing address");
                    }
                    break;
            }
        },
    },
};
</script>

<style scoped>
.verify label {
    margin-left: 8px !important;
}
</style>